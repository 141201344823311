import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import Layout from '../../components/Layout/index'
import PressRelease from '../../components/PressRelease'
import SEO from '../../components/seo'
import Footer from '../../modules/Footer'
import Header from '../../modules/Header'

import Image from '../../images/news/festo-teaser.jpg'
import metaDescription from '../../messages/pageMetaDescriptions.lang'
import messages from '../../messages/pageTitles.lang'

import { newsList } from '../../components/NewsPage/data'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleNewsFesto)}
        imageAlt={formatMessage(messages.TitleNewsFesto)}
        description={formatMessage(metaDescription.NewsFestoDescription)}
        image={Image}
        lang={locale}
      />
      <PressRelease
        article={newsList.festo}
        articleLinks={{
          engineering: (
            <Link to="/services/system-engineering/">
              https://www.freshcells.de/{locale}/services/system-engineering/
            </Link>
          ),
          modernization: (
            <Link to="/solutions/it-system-modernization/">
              https://www.freshcells.de/{locale}
              /solutions/it-system-modernization/
            </Link>
          )
        }}
      />
    </Layout>
  )
}

export default IndexPage
